import { useUrlSearchParams as vueUseUrlSearchParams } from "@vueuse/core";
import type { UrlParams } from "@vueuse/core/index.cjs";

/**
 *  wrapper for @vueuse useUrlSearchParams
 *
 * the @vueuse one fails on SSR, this one doesn't
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUrlSearchParams = <T extends Record<string, any> = UrlParams>(
  ...args: Parameters<typeof vueUseUrlSearchParams>
): ReturnType<typeof vueUseUrlSearchParams> => {
  if (typeof window === "undefined") {
    const query = useRoute().query;
    return query as unknown as T; // a little optimistic but works for now
  }

  return vueUseUrlSearchParams(...args);
};
