import {
  CheckoutCreateDocument,
  type CheckoutCreateMutation,
  type CheckoutLineInput,
} from "@graphql";

import type { FunctionWithClient } from "../types";

type CreateVariables = FunctionWithClient<{
  channel: string;
  lines: CheckoutLineInput[];
}>;

export const create = async ({
  client,
  variables,
}: CreateVariables): Promise<
  Result<string, NonNullable<NonNullable<CheckoutCreateMutation["checkoutCreate"]>["checkout"]>>
> => {
  const { data } = await client.mutate({
    mutation: CheckoutCreateDocument,
    variables,
  });

  if (!data?.checkoutCreate?.checkout) {
    return {
      success: false,
      error: "No checkout created",
    };
  }

  return {
    success: true,
    value: data.checkoutCreate.checkout,
  };
};
