<template>
  <footer class="mt-[60px] w-full border-t border-gray3 pt-10">
    <div class="m-auto max-w-7xl px-3 md:px-6">
      <ul class="grid grid-cols-2 gap-x-5 gap-y-10 xs:grid-cols-3 md:grid-cols-6">
        <li>
          <NuxtImg src="/logo.svg" class="h-10" alt="Genotic logo" :height="35" :width="88" />
        </li>
        <li v-for="item in items" :key="item.label">
          <ul class="grid w-full gap-3 md:gap-5">
            <li class="label">
              <NuxtLinkLocale :href="item.link" :aria-label="item.label">
                {{ item.label }}
              </NuxtLinkLocale>
            </li>
            <li v-for="subitem in item.subcategories" :key="subitem.label" class="text-lighter">
              <NuxtLinkLocale :href="subitem.link" :aria-label="subitem.label">
                {{ subitem.label }}

                <span
                  v-if="subitem.isNew"
                  class="text-12 ml-2 rounded-md bg-gray5 px-1.5 py-0.5 !text-white"
                >
                  {{ $t("footer.new") }}
                </span>
                <span
                  v-else-if="subitem.hiring"
                  class="text-12 inline-block rounded-md border border-gray5 bg-primary px-1.5 py-0.5 !text-white"
                >
                  {{ $t("footer.hiring") }}
                </span>
              </NuxtLinkLocale>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="m-auto mt-6 flex max-w-7xl gap-2 px-3 text-gray4 sm:mt-12 md:px-6">
      <a
        href="https://twitter.com/GenoticInc"
        aria-label="Genotic twitter"
        class="p-2 hover:text-twitter"
      >
        <Icon icon="mdi:twitter" class="transition-colors" />
      </a>
      <a
        href="https://www.linkedin.com/company/genotic"
        aria-label="Genotic linkedin"
        class="p-2 hover:text-linkedin"
      >
        <Icon icon="mdi:linkedin" class="transition-colors" />
      </a>
      <a href="" aria-label="Genotic facebook" class="p-2 hover:text-facebook">
        <Icon icon="mdi:facebook" class="transition-colors" />
      </a>
    </div>
    <div class="mt-6 w-full bg-text-main px-3 sm:mt-12 md:px-6">
      <div
        class="mx-auto flex max-w-7xl flex-col gap-2 py-2 text-white xs:flex-row sm:gap-6 md:gap-12"
      >
        <p class="px py align-middle xxs:px-3 xs:py-2">2024 Genotic. All rights reserved</p>
        <NuxtLinkLocale
          href="/terms-and-conditions"
          class="text-12 xs:text-14 text-white! px py inline-block xxs:px-3 xs:py-2"
        >
          {{ $t("footer.termsAndConditions") }}
        </NuxtLinkLocale>
        <NuxtLinkLocale
          href="/privacy-policy"
          class="text-12 xs:text-14 px py inline-block xxs:px-3 xs:py-2"
        >
          {{ $t("footer.privacyPolicy") }}
        </NuxtLinkLocale>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import type { DefaultLayoutQuery } from "@graphql";
import { Icon } from "@iconify/vue";

const { footer } = defineProps<{
  footer: DefaultLayoutQuery["footer"];
}>();

type Item = {
  label: string;
  link?: string;
  subcategories?: { label: string; link?: string; isNew?: boolean; hiring?: boolean }[];
};

const items = computed<Item[]>(
  () =>
    footer?.items?.map((item) => ({
      label: item.translation?.name ?? item.name,
      link: item.url ?? undefined,
      subcategories: item.children?.map((subItem) => ({
        label: subItem.translation?.name ?? subItem.name,
        link: subItem.url ?? undefined,
        isNew: subItem.name === "Marketing" && item.name === "Product",
        hiring: subItem.name === "Jobs",
      })),
    })) ?? []
);
</script>
