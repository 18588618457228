import { CountryCode as CountryCodeEnum, LanguageCodeEnum } from "@graphql";
import type { LocaleObject } from "@nuxtjs/i18n";

import type { SnakeToKebabCase } from "~/types";

export type LanguageCode = SnakeToKebabCase<`${LanguageCodeEnum}`>;

export const toLanguageCode = <T extends string | LanguageCode>(
  str: T
): T extends LanguageCode ? LanguageCodeEnum : LanguageCodeEnum | undefined => {
  const transformedString = (str.charAt(0).toUpperCase() +
    str.slice(1)) as keyof typeof LanguageCodeEnum;

  return LanguageCodeEnum[transformedString];
};

// TODO: tests would be nice, or changing CountryCodeEnum to string literal in codegen
export const toCountryCode = <T extends string | LocaleObject["channel"]>(
  str: T
): T extends LocaleObject["channel"] ? CountryCodeEnum : CountryCodeEnum | undefined => {
  const transformedString = str.charAt(0).toUpperCase() + str.charAt(1).toLowerCase();

  return CountryCodeEnum[transformedString as keyof typeof CountryCodeEnum];
};

// allow null/undefined values but filter them out
export const toMetadata = (data: Record<string, string | null | undefined>) => {
  return Object.entries(data).reduce<Array<{ key: string; value: string }>>((acc, [key, value]) => {
    if (value) {
      acc.push({ key, value });
    }
    return acc;
  }, []);
};
