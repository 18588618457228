<template>
  <MobileOverlayRoot v-model:open="open">
    <search class="p-3">
      <form>
        <fieldset>
          <legend class="sr-only">{{ $t("common.search") }}</legend>
          <label for="search" class="light">{{ $t("common.search") }}</label>
          <input
            id="search"
            :value="searchParams.search"
            :placeholder="$t('common.search')"
            class="block w-full rounded-lg border-2 border-gray3 px-3 py-2 text-sm text-text-main focus:border-2 focus:border-primary focus:outline-none focus:ring-primary"
            required
            @input="onSearch"
          />
        </fieldset>
      </form>
      <template v-if="data?.length">
        <Separator class="my-4 bg-gray4" />
        <p class="mb-3">{{ $t("search.searchResultsInIndividualCategories") }}</p>
        <ul class="flex flex-col gap-3">
          <Result
            v-for="result in data"
            :key="result.id"
            :category="result.name"
            :href="`/categories/${result.slug}?search=${searchParams.search}`"
            class="!rounded-lg bg-white text-text-main"
            :count="result.productsCount"
            :loading="loading"
            @click="open = false"
          />
        </ul>
      </template>
    </search>
  </MobileOverlayRoot>
</template>
<script setup lang="ts">
import { useGlobalSearch } from "~/src/search/composables/useGlobalSearch";
import { searchStore } from "~/stores/search";

import Result from "../ui/GlobalSearch/Result.vue";
import MobileOverlayRoot from "./MobileOverlayRoot.vue";

const open = defineModel<boolean>("open", { default: false });

const searchParams = useUrlSearchParams<{ search?: string }>();

const searchRef = computed<string>({
  get: () => searchParams.search,
  set: (value) => {
    searchParams.search = value;
    searchStore.search = value;
  },
});

const { search: _search, data, debouncedSearch, loading } = useGlobalSearch();

const onSearch = (e: Event) => {
  const input = e.target as HTMLInputElement;
  searchRef.value = input.value;
  debouncedSearch(input.value);
};

onMounted(() => {
  // NOTE: workaround when user navigates with search params
  if (searchParams.search?.length && !data.value?.length) {
    _search(searchParams.search);
  }
});
</script>
