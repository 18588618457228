import { DEFAULT_TOAST_DURATION } from "~/constants";

export const cartStore = reactive({
  open: false,
  timer: 0,
  setOpenWithTimeout(timeout: number = DEFAULT_TOAST_DURATION) {
    this.open = true;
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.open = false;
    }, timeout);
  },
});
