<template>
  <SelectRoot v-model="value">
    <SelectTrigger
      :class="
        twMerge(
          'inline-flex h-[35px] items-center justify-between gap-[5px] rounded bg-white px-[15px] text-[13px] leading-none text-text-main outline-none hover:bg-mauve3 focus:shadow-black',
          triggerClass
        )
      "
      aria-label="Customize options"
      v-bind="$attrs"
    >
      <slot name="trigger-prefix" />
      <SelectValue :placeholder="placeholder" class="text-nowrap" />
      <NuxtImg src="/icons/chevron-down.svg" :alt="$t('common.open')" class="inline" />
    </SelectTrigger>

    <SelectPortal>
      <SelectContent
        class="z-[100] rounded bg-white will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade"
        :side-offset="5"
      >
        <SelectViewport class="p-[5px]">
          <SelectLabel v-if="label" class="px-[25px] text-xs leading-[25px] text-mauve11">
            {{ label }}
          </SelectLabel>
          <SelectGroup>
            <SelectItem
              v-for="option in options"
              :key="option.value"
              class="relative flex h-[25px] select-none items-center rounded-[3px] pl-[25px] pr-[35px] text-[13px] leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-text-main data-[disabled]:text-mauve8 data-[highlighted]:text-primary-lightest data-[highlighted]:outline-none"
              :value="option.value"
              :disabled="option.disabled"
            >
              <SelectItemIndicator
                class="absolute left-0 inline-flex w-[25px] items-center justify-center"
              >
                <Icon icon="radix-icons:check" />
              </SelectItemIndicator>
              <SelectItemText>
                {{ option?.label ?? option.value }}
              </SelectItemText>
            </SelectItem>
          </SelectGroup>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import type { SelectRootProps } from "radix-vue";
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from "radix-vue";
import { twMerge } from "tailwind-merge";
import { useField } from "vee-validate";

export type SelectOption = { value: string; label?: string; disabled?: boolean };

export type SelectProps = Pick<SelectRootProps, "disabled" | "defaultValue"> & {
  placeholder?: string;
  label?: string;
  options: SelectOption[];
  triggerClass?: string;
  id?: string;
  name?: string;
};

defineSlots<{
  "trigger-prefix"(): unknown;
}>();
const props = defineProps<SelectProps>();

const fieldId = useId();

const { value } = useField<string>(() => props.id ?? props.name ?? fieldId!, undefined, {
  syncVModel: true,
});
</script>
