<template>
  <div class="z-20 bg-text-main">
    <NavigationMenuRoot v-model="currentTrigger" class="relative z-[1] mx-auto flex max-w-7xl">
      <NavigationMenuList
        class="m-auto flex list-none gap-6 rounded-[6px] px-3 py-2 text-white sm:px-6"
      >
        <NavigationMenuItem v-for="item in navigation?.items" :key="item.name" :value="item.id">
          <NavigationMenuTrigger
            class="focus:shadow-white-500 group flex select-none items-center justify-between gap-[2px] rounded border-white px-3 py-2 underline-offset-4 outline-none transition-all hover:underline focus:shadow-[0_0_0_2px]"
            :as-child="!item?.children?.length"
          >
            <template v-if="item?.children?.length">
              {{ item.translation?.name ?? item.name }}
              <NuxtImg src="/icons/chevron-down.svg" :alt="$t('common.open')" />
            </template>
            <NuxtLinkLocale v-else :href="getRelativeUrl(item.url)">
              {{ item.translation?.name ?? item.name }}
            </NuxtLinkLocale>
          </NavigationMenuTrigger>
          <NavigationMenuContent v-if="item?.children?.length" class="w-screen">
            <NavigationMenuSub v-model="currentSubTrigger" class="flex w-full">
              <NavigationMenuList
                class="border-bl-lg flex h-full flex-col divide-y border-b border-l border-r border-gray3 p-3 transition-all"
              >
                <NavigationMenuItem v-for="subitem in item?.children" :key="subitem.name">
                  <NavigationMenuTrigger
                    class="text-16 group flex w-72 select-none items-center justify-between rounded px-2 py-4 outline-none hover:text-primary focus:shadow-[0_0_0_2px] focus:shadow-primary"
                  >
                    <NuxtLinkLocale
                      :href="subitem.category?.slug && `/categories/${subitem.category.slug}`"
                      class="flex w-full items-center justify-between"
                    >
                      {{ subitem.translation?.name ?? subitem.name }}

                      <Icon
                        v-if="subitem.children?.length"
                        icon="radix-icons:chevron-right"
                        class="h-7 w-7 text-primary"
                      />
                    </NuxtLinkLocale>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent class="border-br-lg w-full p-5">
                    <ul class="one m-0 grid grid-cols-3 gap-5">
                      <li v-for="subL2item in subitem?.children" :key="subL2item.name">
                        <!-- users might think this is a button to open the menu think of something better -->
                        <NuxtLinkLocale
                          :href="
                            (subL2item.category && `/categories/${subL2item.category.slug}`) ??
                            undefined
                          "
                          class="text-16"
                        >
                          {{ subL2item.translation?.name ?? subL2item.name }}
                        </NuxtLinkLocale>
                        <ul class="mt-4 flex flex-col gap-3">
                          <li
                            v-for="subL3item in subL2item?.children"
                            :key="subL3item.name"
                            class="text-lighter"
                          >
                            <NuxtLinkLocale
                              :href="
                                (subL3item.category && `/categories/${subL3item.category.slug}`) ??
                                undefined
                              "
                            >
                              {{ subL3item.translation?.name ?? subL3item.name }}
                            </NuxtLinkLocale>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>

              <NavigationMenuViewport />
            </NavigationMenuSub>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
      <div
        v-if="currentTriggerValue?.children?.length"
        class="absolute left-0 top-full flex w-full justify-center perspective-[2000px]"
      >
        <NavigationMenuViewport
          class="relative h-[var(--radix-navigation-menu-viewport-height)] w-screen origin-[top_center] overflow-hidden bg-white transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(--radix-navigation-menu-viewport-width)]"
        />
      </div>
    </NavigationMenuRoot>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuRoot,
  NavigationMenuSub,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "radix-vue";

import type { DefaultLayoutQuery } from "~/src/api/generated/graphql";

export type Navigation = DefaultLayoutQuery["navigation"];

const { navigation } = defineProps<{
  navigation: Navigation;
}>();

const currentSubTrigger = ref("");
const currentTrigger = ref("");

const currentTriggerValue = computed(() =>
  navigation?.items?.find((item) => item.id === currentTrigger.value)
);
</script>
<script lang="ts">
export const getRelativeUrl = (url: string | null | undefined = ""): string | undefined => {
  if (!url) return;

  return new URL(url).pathname;
};
</script>
<style lang="css" scoped>
.list > li {
  border-bottom: 1px solid #e1e4ea;

  &:last-child {
    border-bottom: none;
  }
}
</style>
