import type { ApolloClient } from "@apollo/client/core";

import {
  type CheckoutCartFragment,
  CheckoutCartFragmentDoc,
  CheckoutDeleteLinesDocument,
} from "~/src/api/generated/graphql";

export const deleteLines = async (
  lineIds: string | string[],
  client: ApolloClient<unknown>,
  checkoutId: string | null | undefined
): Promise<void> => {
  if (!checkoutId) {
    throw new Error("No checkout, this should never happen");
  }

  const currentCheckout = client.readFragment<CheckoutCartFragment>({
    id: `Checkout:${checkoutId}`,
    fragment: CheckoutCartFragmentDoc,
  });

  const linesToDelete = currentCheckout?.lines.filter((line) => {
    if (Array.isArray(lineIds)) {
      return lineIds.includes(line.id);
    } else {
      return lineIds === line.id;
    }
  });

  const linesToDeleteTotal =
    linesToDelete?.reduce((acc, line) => acc + line.totalPrice.gross.amount, 0) ?? 0;

  const optimisticCheckout = {
    ...currentCheckout,
    lines: currentCheckout?.lines.filter((line) => !lineIds.includes(line.id)) ?? [],
    totalPrice: {
      ...currentCheckout?.totalPrice,
      gross: {
        ...currentCheckout?.totalPrice.gross,
        amount: (currentCheckout?.totalPrice.gross.amount || 0) - linesToDeleteTotal,
      },
    },
  };

  await client.mutate({
    mutation: CheckoutDeleteLinesDocument,
    variables: {
      checkoutId,
      lineIds,
    },
    optimisticResponse: {
      checkoutLinesDelete: {
        __typename: "CheckoutLinesDelete",
        checkout: optimisticCheckout as unknown as CheckoutCartFragment,
        errors: [],
      },
    },
    update: (cache, { data }) => {
      if (data?.checkoutLinesDelete?.checkout) {
        cache.writeFragment({
          id: `Checkout:${checkoutId}`,
          fragment: CheckoutCartFragmentDoc,
          data: data.checkoutLinesDelete.checkout,
        });
      }
    },
  });
};
